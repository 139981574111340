import { FC, useEffect } from 'react';

import { ServiceRequest } from 'roslib';
import { useServiceCaller } from '../../services/Ros/ServiceCaller';
import { useConnection } from '../../services/Ros/Connection';

interface TeleopToggleProps {
  activateTeleop: boolean; // Prop that triggers the service call
}

export const TeleopToggle: FC<TeleopToggleProps> = ({ activateTeleop }) => {
  const callTeleopService = useServiceCaller(); // Use the service caller hook
  const readyState = useConnection();

  useEffect(() => {
    const request: ServiceRequest = { data: activateTeleop }; // Construct the request payload

    const triggerServiceCall = async () => {
        if (readyState){
            try {
                const result = await callTeleopService(request);
                console.log('Teleop service result:', result);
            } catch (error) {
                console.error('Failed to call teleop service:', error);
            }
        }
    };

    triggerServiceCall();
  }, [activateTeleop, callTeleopService]); // Re-run when the activateTeleop prop changes

  return null;
};

export default TeleopToggle;
