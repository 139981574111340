import { createContext, useContext, useEffect, useState, FC } from "react";
import { useRos } from "./utils/rosProvider";
import { Service, ServiceRequest } from 'roslib';

interface IServiceCaller {
    name: string;
    type: string;
}

const ServiceContext = createContext<(request: ServiceRequest) => Promise<any>>(async () => {});

/**
 * 
 * @param name - the name of the service
 * @param type - the service type
 * @returns 
 */
export const ServiceCaller: FC<IServiceCaller> = ({ children, name, type }) => {
    const ROS = useRos();
    const [service, setService] = useState<Service | null>(null);

    useEffect(() => {
        const rosService = new Service({
            ros: ROS,
            name,
            serviceType: type
        });

        setService(rosService);

        // Cleanup by removing the service when the component unmounts
        return () => {
            console.log('clean up service');
            setService(null);
        };
    }, [ROS, name, type]);

    const callService = async (request: ServiceRequest) => {
        return new Promise((resolve, reject) => {
            if (service) {
                service.callService(request, (result) => {
                    resolve(result);
                }, (error) => {
                    reject(error);
                });
            } else {
                reject(new Error('Service not available'));
            }
        });
    };

    return (
        <ServiceContext.Provider value={callService}>
            {children}
        </ServiceContext.Provider>
    );
};

export const useServiceCaller = () => {
    const context = useContext(ServiceContext);
    if (context === undefined) {
        throw new Error('useServiceCaller must be used within a ServiceCaller provider');
    }
    return context;
};
